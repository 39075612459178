import Cookies from "js-cookie";
import { trackEvent } from "@lib/track-event";
import { LineItem } from "../LineItem";
import { CartDrawerFooter } from "../Drawer/CartDrawerFooter";
import { CartDrawerEmpty } from "../Drawer/CartDrawerEmpty";
import { logError } from "@lib/logger";
import { useCheckoutState, useCheckoutDispatch } from "@hooks/useCheckout";
import { resolveSubtotal } from "../Drawer/lib/resolve-subtotal";
import { resolveTotal } from "../Drawer/lib/resolve-total";
import styles from "./styles/section.module.css";

export const CartSection = () => {
  const { cart, loading } = useCheckoutState();
  const { updateCartItem } = useCheckoutDispatch();

  const lineItems = cart ? cart.lineItems.edges : [];
  const subtotal = resolveSubtotal(cart);
  const location = Cookies.get("location") ? JSON.parse(Cookies.get("location")) : "N/A";
  const total = resolveTotal(cart);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      trackEvent("begin_checkout", { checkout });
      window.sessionStorage.removeItem("__LSM__");
      window.open(cart.webUrl, "_self", "", true);
    } catch (error) {
      logError(error);
    }
  };

  if (lineItems.length === 0) {
    return (
      <main className={styles.wrapper}>
        <article className={`${styles.container} ${styles.emptyContainer}`}>
          <CartDrawerEmpty location={location} />
        </article>
      </main>
    );
  }

  return (
    <main className={styles.wrapper}>
      <article className={styles.container}>
        <ul className={styles.list}>
          {lineItems
            .concat()
            .reverse()
            .map(({ node }) => (
              <LineItem key={node.id} item={node} loading={loading} onItemUpdate={updateCartItem} />
            ))}
        </ul>

        <div className={styles.form}>
          <h2 className={styles.title}>Your bag</h2>
          <ul className={styles.receipt}>
            <li className={styles.line}>
              <span>Subtotal</span>
              <span>{subtotal}</span>
            </li>
          </ul>
          <CartDrawerFooter
            handleSubmit={handleSubmit}
            loading={loading}
            location={location}
            total={total}
          />
        </div>
      </article>
    </main>
  );
};
